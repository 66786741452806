import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'

import tw from 'twin.macro'

import Layout from '../components/layout'

const TextInput = tw.input`mt-1 block w-full rounded-md border-border border-opacity-30 bg-darkest text-text shadow-sm focus:border-focus focus:ring focus:ring-text-secondary focus:ring-opacity-20`
const SelectInput = tw.select`block w-full mt-1 rounded-md border-border border-opacity-30 bg-darkest text-text shadow-sm focus:border-focus focus:ring focus:ring-text-secondary focus:ring-opacity-20`

const Panel = tw.div`p-8 bg-dark rounded`
const Label = tw.span`text-text-secondary`

const HomeEntry = () => {
  const [baseCurrency, setBaseCurrency] = useState('USDT')
  const [accountSize, setAccountSize] = useState('')
  const [risk, setRisk] = useState(1)
  const [leverage, setLeverage] = useState(1)
  const [stopLoss, setStopLoss] = useState(1)
  const [marketFee, setMarketFee] = useState(0.06)
  const [price, setPrice] = useState(null)
  const [positionSize, setPositionSize] = useState('')
  const [coinLabel, setCoinLabel] = useState('BTC')
  const [takeProfit1, setTakeProfit1] = useState(100)
  const [takeProfit2, setTakeProfit2] = useState('')
  const [takeProfit3, setTakeProfit3] = useState('')
  const [takeProfit4, setTakeProfit4] = useState('')
  const [takeProfit5, setTakeProfit5] = useState('')
  const [takeProfit6, setTakeProfit6] = useState('')
  const [entryPrice, setEntryPrice] = useState()
  const [stopLossPrice, setStopLossPrice] = useState('')
  const [takeProfit1Price, setTakeProfit1Price] = useState()
  const [takeProfit2Price, setTakeProfit2Price] = useState('')
  const [takeProfit3Price, setTakeProfit3Price] = useState('')
  const [takeProfit4Price, setTakeProfit4Price] = useState('')
  const [takeProfit5Price, setTakeProfit5Price] = useState('')
  const [takeProfit6Price, setTakeProfit6Price] = useState('')

  const usdPrecision = 4
  const btcPrecision = 8

  const formatAmount = amount => {
    if (baseCurrency === 'USDT') {
      return parseFloat(amount).toFixed(usdPrecision)
    } else {
      return +parseFloat(amount).toFixed(btcPrecision).toString()
    }
  }

  const formatWithMinimumDecimalPlaces = (amount, noOfPlaces) => {
    return amount.toFixed(
      Math.max(noOfPlaces, (amount.toString().split('.')[1] || []).length)
    )
  }

  const calculatePositionSize = () => {
    const posSize =
      (accountSize * (risk / 100)) / (stopLoss / 100 + 2 * (marketFee / 100))
    return formatAmount(posSize)
  }

  const calculateActualPositionCost = () => {
    return formatAmount(
      (accountSize * (risk / 100)) /
        (stopLoss / 100 + 2 * (marketFee / 100)) /
        leverage
    )
  }

  const calculateTpSplit = (takeProfit, label) => {
    return (
      label +
      ': ' +
      formatWithMinimumDecimalPlaces(positionSize * (takeProfit / 100), 1) +
      ' ' +
      coinLabel
    )
  }

  const calculateTakeProfitAveragePrice = () => {
    const currentPercent =
      (parseFloat(takeProfit1) || 0) +
      (parseFloat(takeProfit2) || 0) +
      (parseFloat(takeProfit3) || 0) +
      (parseFloat(takeProfit4) || 0) +
      (parseFloat(takeProfit5) || 0) +
      (parseFloat(takeProfit6) || 0)

    console.log(currentPercent)

    if (currentPercent !== 100) {
      return false
    }

    let total = 0

    if (takeProfit1 && takeProfit1Price) {
      total += takeProfit1Price * (takeProfit1 / 100)
    }

    if (takeProfit2 && takeProfit2Price) {
      total += takeProfit2Price * (takeProfit2 / 100)
    }

    if (takeProfit3 && takeProfit3Price) {
      total += takeProfit3Price * (takeProfit3 / 100)
    }

    if (takeProfit4 && takeProfit4Price) {
      total += takeProfit4Price * (takeProfit4 / 100)
    }

    if (takeProfit5 && takeProfit5Price) {
      total += takeProfit5Price * (takeProfit5 / 100)
    }

    if (takeProfit6 && takeProfit6Price) {
      total += takeProfit6Price * (takeProfit6 / 100)
    }

    return total
  }

  const calculateR = () => {
    const takeProfitAveragePrice = calculateTakeProfitAveragePrice()

    if (takeProfitAveragePrice) {
      const riskAmount = Math.abs(entryPrice - stopLossPrice)
      const profitAmount = Math.abs(entryPrice - takeProfitAveragePrice)

      return profitAmount / riskAmount
    } else {
      return ''
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Trade Calculator</title>
      </Helmet>
      <div tw="container">
        <div tw="max-w-4xl mx-auto">
          <div tw="flex flex-col items-center py-8 text-center">
            <h1 tw="font-heading text-2xl">Trade Calculator</h1>
          </div>

          <div tw="grid grid-cols-2 gap-12 mb-24">
            <Panel>
              <h2>Trade requirements</h2>

              <div tw="block mt-4">
                <Label>Base currency</Label>
                <div tw="mt-2">
                  <SelectInput onChange={e => setBaseCurrency(e.target.value)}>
                    <option name="usdt">USDT</option>
                    <option name="btc">BTC</option>
                  </SelectInput>
                </div>
              </div>

              <div tw="block mt-4">
                <Label>
                  Account size ({baseCurrency === 'USDT' ? '$' : 'BTC'})
                </Label>
                <TextInput
                  type="number"
                  value={accountSize}
                  placeholder={baseCurrency === 'USDT' ? '2000' : '0.01'}
                  onChange={e => setAccountSize(e.target.value)}
                />
              </div>

              <div tw="block mt-4">
                <Label>Risk (%)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={risk}
                    onChange={e => setRisk(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Leverage (x)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={leverage}
                    onChange={e => setLeverage(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Stop loss (%)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={stopLoss}
                    onChange={e => setStopLoss(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Market fee (%)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={marketFee}
                    onChange={e => setMarketFee(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Price ($)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4 border-border border-opacity-40 border-t pt-6">
                <Label>Entry price ($)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="text"
                    value={entryPrice}
                    onChange={e => setEntryPrice(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Stop loss price ($)</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={stopLossPrice}
                    onChange={e => setStopLossPrice(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Take profits</Label>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit1Price}
                      onChange={e => setTakeProfit1Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit1}
                      placeholder="%"
                      onChange={e => setTakeProfit1(e.target.value)}
                    />
                  </div>
                </div>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit2Price}
                      onChange={e => setTakeProfit2Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit2}
                      placeholder="%"
                      onChange={e => setTakeProfit2(e.target.value)}
                    />
                  </div>
                </div>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit3Price}
                      onChange={e => setTakeProfit3Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit3}
                      placeholder="%"
                      onChange={e => setTakeProfit3(e.target.value)}
                    />
                  </div>
                </div>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit4Price}
                      onChange={e => setTakeProfit4Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit4}
                      placeholder="%"
                      onChange={e => setTakeProfit4(e.target.value)}
                    />
                  </div>
                </div>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit5Price}
                      onChange={e => setTakeProfit5Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit5}
                      placeholder="%"
                      onChange={e => setTakeProfit5(e.target.value)}
                    />
                  </div>
                </div>
                <div tw="mt-2 flex gap-x-2">
                  <div tw="w-3/4">
                    <TextInput
                      type="number"
                      value={takeProfit6Price}
                      onChange={e => setTakeProfit6Price(e.target.value)}
                    />
                  </div>
                  <div tw="w-1/4">
                    <TextInput
                      type="number"
                      value={takeProfit6}
                      placeholder="%"
                      onChange={e => setTakeProfit6(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Position size ({coinLabel})</Label>
                <div tw="mt-2">
                  <TextInput
                    type="number"
                    value={positionSize}
                    placeholder={0.1}
                    onChange={e => setPositionSize(e.target.value)}
                  />
                </div>
              </div>

              <div tw="block mt-4">
                <Label>Coin currency</Label>
                <div tw="mt-2">
                  <TextInput
                    type="text"
                    value={coinLabel}
                    onChange={e => setCoinLabel(e.target.value)}
                  />
                </div>
              </div>
            </Panel>

            <Panel>
              <div tw="text-text-secondary">
                {(accountSize && risk && leverage && takeProfit1 && stopLoss) ||
                (positionSize &&
                  (takeProfit1 ||
                    takeProfit2 ||
                    takeProfit3 ||
                    takeProfit4 ||
                    takeProfit5 ||
                    takeProfit6)) ||
                (entryPrice &&
                  stopLossPrice &&
                  ((takeProfit1Price && takeProfit1) ||
                    (takeProfit2Price && takeProfit2) ||
                    (takeProfit3Price && takeProfit3) ||
                    (takeProfit4Price && takeProfit4) ||
                    (takeProfit5Price && takeProfit5) ||
                    (takeProfit6Price && takeProfit6))) ? (
                  <>
                    {accountSize &&
                    risk &&
                    leverage &&
                    takeProfit1 &&
                    stopLoss ? (
                      <div tw="text-base mb-6 pb-6 border-b border-border border-opacity-40">
                        <h2 tw="text-text">Trade entry</h2>
                        <div tw="mb-1">
                          Total position size:{' '}
                          {baseCurrency === 'USDT' ? '$' : ''}
                          {calculatePositionSize()}
                          {baseCurrency === 'BTC' ? ' BTC' : ''}
                        </div>
                        {parseFloat(leverage) !== 1 ? (
                          <div tw="mb-1">
                            Actual position cost:{' '}
                            {baseCurrency === 'USDT' ? '$' : ''}
                            {calculateActualPositionCost()}
                            {baseCurrency === 'BTC' ? ' BTC' : ''}
                          </div>
                        ) : (
                          <></>
                        )}
                        {price ? (
                          <div tw="mb-1">
                            Quantity to purchase:{' '}
                            {baseCurrency === 'BTC' ? '$' : ''}
                            {baseCurrency === 'BTC'
                              ? Math.round(calculatePositionSize() * price)
                              : (calculatePositionSize() / price).toFixed(3)}
                            {baseCurrency === 'USDT' ? '' : ''}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {positionSize &&
                    (takeProfit1 ||
                      takeProfit2 ||
                      takeProfit3 ||
                      takeProfit4 ||
                      takeProfit5 ||
                      takeProfit6) ? (
                      <div tw="text-base mb-6 pb-6 border-b border-border border-opacity-40">
                        <h2 tw="text-text">Take profits</h2>
                        <div tw="mb-1">
                          {takeProfit1 && calculateTpSplit(takeProfit1, 'TP1')}
                        </div>
                        <div tw="mb-1">
                          {takeProfit2 && calculateTpSplit(takeProfit2, 'TP2')}
                        </div>
                        <div tw="mb-1">
                          {takeProfit3 && calculateTpSplit(takeProfit3, 'TP3')}
                        </div>
                        <div tw="mb-1">
                          {takeProfit4 && calculateTpSplit(takeProfit4, 'TP4')}
                        </div>
                        <div tw="mb-1">
                          {takeProfit5 && calculateTpSplit(takeProfit5, 'TP5')}
                        </div>
                        <div tw="mb-1">
                          {takeProfit6 && calculateTpSplit(takeProfit6, 'TP6')}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {entryPrice &&
                    stopLossPrice &&
                    ((takeProfit1Price && takeProfit1) ||
                      (takeProfit2Price && takeProfit2) ||
                      (takeProfit3Price && takeProfit3) ||
                      (takeProfit4Price && takeProfit4) ||
                      (takeProfit5Price && takeProfit5) ||
                      (takeProfit6Price && takeProfit6)) ? (
                      <div tw="text-base">
                        <h2 tw="text-text">
                          Average TP: ${calculateTakeProfitAveragePrice()}
                        </h2>
                        <h2 tw="text-text">R: {calculateR()}</h2>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <div>Enter your data on the left.</div>
                )}
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomeEntry
